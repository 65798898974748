import { Select } from '@truphone/core-components'
import React, { useState } from 'react'
import { uppercaseString } from '@truphone/utils'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { PrimaryButton } from '@truphone/buttons'
import { SliderCalculator } from '@truphone/slider-components'

export default function TechnologyDataPackages(props) {
  const [selectedData, setSelectedData] = useState(null)
  const cta = props.boxCta ? props.boxCta[0] : null
  return (
    <div className="relative overflow-hidden">
      {props.image && (
        <ImageWrap
          className={`absolute left-1/2 -translate-x-1/2 lg:-translate-y-1/2 lg:left-auto bottom-0 lg:-right-0 lg:translate-x-0 lg:top-1/2 max-w-3xl w-[620px] lg:w-1/2 lg:max-w-none`}
          {...props.image}
        />
      )}
      <div
        className={`text-black flex flex-col items-center relative container py-10 ${
          props.imageRight ? 'lg:flex-row-reverse' : 'lg:flex-row'
        }  ${
          props.imageRight ? 'flex-col-reverse lg:justify-start' : ''
        } px-7 md:px-10 lg:px-4 mx-auto`}
      >
        <div className="w-full lg:w-1/2">
          {props.topTitle && (
            <p className="overline-text mb-4">{uppercaseString(props.topTitle)}</p>
          )}

          {props.title && <h3 className="h4-normal mb-6">{props.title}</h3>}
          {props.text && (
            <div
              className={`text-base list-disc p-margin`}
              dangerouslySetInnerHTML={{ __html: props.text }}
            />
          )}
        </div>
        <div className="w-full lg:w-1/2 justify-center relative">
          <div className="mt-10 lg:mt-0 relative bg-white max-w-xs mx-auto rounded-lg shadow lg:shadow-lg p-8">
            {props.boxFormTitle && (
              <h4 className="text-xs uppercase text-center mb-4 font-semibold">
                {' '}
                {props.boxFormTitle}
              </h4>
            )}
            {props.boxFormFields &&
              props.boxFormFields.map((field, i) => {
                return field.type === 'Select' && field.selectSlider ? (
                  <div key={`select-field-${i}`}>
                    {field.label && <p className="text-xs my-3">{field.label}</p>}
                    <SliderCalculator
                      badgeFormat={field.badgeFormat}
                      technology
                      selectFieldOptions={field.selectFieldOptions}
                      value={field.selectFieldOptions[0].value}
                    />
                  </div>
                ) : (
                  field.type === 'Select' && (
                    <div key={`select-field-${i}`}>
                      <p className="text-xs mb-4">{field.label}</p>

                      <div className="rounded border border-black mt-4 w-full text-center">
                        <Select
                          heron
                          className={''}
                          onChange={(value) => setSelectedData(value)}
                          options={field.selectFieldOptions}
                          value={selectedData ? selectedData : field.selectFieldOptions[0].value}
                        />
                      </div>
                    </div>
                  )
                )
              })}

            {cta && (
              <PrimaryButton className={`w-full mt-4`} href={cta.href}>
                {cta.text}
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
