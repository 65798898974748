import React, { useContext, useState, useEffect } from 'react'
import { ButtonNext, ButtonBack, CarouselContext } from 'pure-react-carousel'
import { ArrowSimple } from '@truphone/core-components'
export default function Navigation(props) {
  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide + 1)
  const totalSlides = props.totalSlides ? props.totalSlides : carouselContext.state.totalSlides
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide + 1)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  const playNext = () => {
    if (props.pauseVideo) {
      props.pauseVideo()
    }
    if (props.handleFade) {
      props.handleFade()
    }
  }
  return (
    <div
      className={`items-center flex w-full justify-between 
      ${
        props.vertical
          ? 'lg:flex-col lg:w-20 lg:p-4 lg:justify-center'
          : 'flex-row lg:justify-start'
      }`}
    >
      <ButtonBack className={`w-8 h-8 p-2 ${props.vertical ? '' : 'lg:hidden'}`} onClick={playNext}>
        <ArrowSimple className={`rotate-90 ${props.vertical ? 'lg:rotate-180' : ''}`} />{' '}
      </ButtonBack>

      <div className={` ${props.vertical ? 'lg:flex flex-col items-center' : 'lg:mr-4'}`}>
        <span>{currentSlide} </span>
        <span className="mx-2">/</span>
        <span> {totalSlides}</span>
      </div>

      <ButtonBack
        className={`w-8 h-8 p-2 hidden ${props.vertical ? '' : 'lg:block'}`}
        onClick={playNext}
      >
        <ArrowSimple className={`rotate-90`} />{' '}
      </ButtonBack>

      <ButtonNext className="w-8 h-8 p-2" onClick={playNext}>
        <ArrowSimple className={`-rotate-90 ${props.vertical ? 'lg:rotate-0' : ''}`} />
      </ButtonNext>
    </div>
  )
}
