import React from 'react'
export default function IconsColumnGroup(props) {
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor?.hex,
        color: props.textColor?.hex
      }}
    >
      <div className="container mx-auto pt-10 pb-4 overflow-hidden">
        <div className="flex flex-wrap -mx-4">
          {props.columns.map((column, i) => {
            return (
              <div
                key={`entitlement-item-${i}`}
                className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 mt-2"
              >
                <h4 className="text-sm uppercase font-semibold mx-6 my-2">{column.title}</h4>
                <div className="lg:grid grid-rows-3 h-full">
                  {column.columnItems.map((item, i) => {
                    return (
                      <div key={`entitlement-item-${i}`} className=" mx-6 my-6 flex">
                        <img src={item.image.url} alt={item.image.alt} className="w-10 h-10 mr-4" />
                        <div className="text-sm" dangerouslySetInnerHTML={{ __html: item.text }} />
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
