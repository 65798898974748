import { ArrowButton, PrimaryButton, SecondaryButton } from '@truphone/buttons'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { uppercaseString } from '@truphone/utils'
import React, { useState } from 'react'
import '../../style.css'
import RevealImage from './RevealImage'

function ThingsSimTabContent(props) {
  const [selectedInnerTab, setSelectedInnerTab] = useState(0)

  return (
    <div className="flex flex-col-reverse lg:flex-row">
      <div className="w-full lg:w-1/2">
        <ImageWrap className="mx-auto" {...props.image} />
      </div>

      <div className="w-full lg:w-1/2">
        <div className="mt-4">
          <h3 className="h4-normal">{props.title}</h3>
          <p className="text-xs text-grey-700 border-b border-grey-200 pb-1 mt-1 mb-4 tracking-wide">
            {props.subtitle}
          </p>
        </div>

        {props.contentNode && (
          <div
            className="text-base list-tick list-tick-green p-margin"
            dangerouslySetInnerHTML={{ __html: props.contentNode.childMarkdownRemark.html }}
          />
        )}

        {props.footerNode && (
          <div
            className="text-sm my-6 p-margin link-blue table-align-top list-disc"
            dangerouslySetInnerHTML={{ __html: props.footerNode.childMarkdownRemark.html }}
          />
        )}

        {props.innerTabs && (
          <div className="flex w-full mx-auto justify-center">
            {props.innerTabs.map((innerTab, i) => (
              <div
                key={`inner-tab-image-${i}`}
                className={`${selectedInnerTab === i ? '' : 'opacity-60'}`}
                onClick={() => setSelectedInnerTab(i)}
              >
                <ImageWrap {...innerTab.tabImage} />
              </div>
            ))}
          </div>
        )}

        {props.innerTabs && (
          <div>
            {props.innerTabs.map((innerTab, i) => (
              <div
                className={`${selectedInnerTab === i ? 'block' : 'hidden'}`}
                key={`inner-tab-content-${i}`}
              >
                <div
                  className="text-sm my-6 p-margin link-blue table-align-top list-disc"
                  dangerouslySetInnerHTML={{
                    __html: innerTab.contentNode.childMarkdownRemark.html
                  }}
                />
                {innerTab.cta && innerTab.cta.length > 0 && (
                  <PrimaryButton
                    className="text-sm lg:text-lg font-semibold inline-flex mx-auto mt-auto p-3"
                    title={innerTab.cta[0].title}
                    href={innerTab.cta[0].href}
                    target={innerTab.cta[0].openTab ? '_blank' : null}
                  >
                    {innerTab.cta[0].text}
                  </PrimaryButton>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default function TechnologyTabs(props) {
  const [selectedTab, setSelectedTab] = useState(0)
  const [previousStep, setPreviousStep] = useState(1)
  const [activeStep, setActiveStep] = useState(0)
  const [activeHover, setActiveHover] = useState(null)
  const cta = props.cta && props.cta[0]

  const icons = props.style === 'icons'
  const homepage = props.style === 'homepage'

  return (
    <div
      className={`${homepage ? 'bg-gradient-home text-white ' : ''}`}
      style={{
        backgroundColor: props.backgroundColor,
        color: props.textColor
      }}
    >
      <div
        className={`container mx-auto px-4 ${
          icons ? 'py-6' : homepage ? 'text-center py-6 lg:py-20' : 'text-center pt-6 pb-4 lg:pb-20'
        }`}
      >
        <div className="overflow-x-auto relative pb-2">
          <div
            className={`${
              icons
                ? 'flex flex-col md:flex-row rounded w-full'
                : homepage
                ? 'inline-flex border border-white rounded-lg'
                : 'inline-flex flex-wrap border border-grey-700 rounded-lg whitespace-nowrap mt-10 mb-4 '
            } mx-auto w-auto relative`}
          >
            {props.containerTabs &&
              props.containerTabs.map((tab, i) => {
                return (
                  <div
                    onClick={() => setSelectedTab(i)}
                    className={`${
                      selectedTab === i
                        ? icons
                          ? 'bg-white text-black rounded bg-opacity-100'
                          : homepage
                          ? 'is-active bg-blue-900 z-1'
                          : 'bg-gray-300 rounded-xl'
                        : ''
                    } cursor-pointer relative 
                ${homepage ? 'tabs-border rounded-lg sm:min-w-[150px] lg:min-w-[250px]' : ''}
                ${
                  icons
                    ? 'flex items-center py-5 px-5 bg-white bg-opacity-10 '
                    : 'px-4 lg:px-10 py-2 lg:py-3 w-full sm:w-auto'
                }`}
                    key={`reveal-tab-${i}`}
                  >
                    {tab.icon && (
                      <div className="rounded-full bg-blue-100 relative mr-10 p-2 w-12 h-12 shrink-0">
                        <img src={tab.icon.url} alt="" />
                      </div>
                    )}
                    <div>
                      <h3 className="text-sm lg:text-base font-bold tracking-wide lg:tracking-xwidest">
                        {uppercaseString(tab.title)}
                      </h3>
                      {tab.subtitle && <p className="text-sm">{tab.subtitle}</p>}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        {props.containerTabs && props.containerTabs.length > 0 && (
          <div className={`${cta ? 'pt-4 pb-10' : 'py-4 lg:py-10'} w-full container mx-auto`}>
            {props.containerTabs.map((tab, i) => {
              return (
                <div
                  key={`tab-component${i}`}
                  className={`w-full z-1 relative text-left ${
                    selectedTab === i ? 'flex flex-wrap justify-around' : 'hidden'
                  } `}
                >
                  {tab.content &&
                    tab.content.map((tabContent, i) => {
                      if (tabContent.type === 'DatoCmsThingsSimTabContent') {
                        return <ThingsSimTabContent key={`tab-component${i}`} {...tabContent} />
                      }
                      return tab.style === 'lists' ? (
                        <div
                          key={`tab-content-${i}`}
                          className={`text-center mb-4 max-w-5xl mx-auto `}
                        >
                          {tabContent.title && (
                            <h3 className="text-3xl mb-4">{tabContent.title}</h3>
                          )}
                          <div
                            className="text-base mb-4"
                            dangerouslySetInnerHTML={{ __html: tabContent.subtitle }}
                          />
                          {tabContent.content && (
                            <div
                              className="text-base text-left list-tickbox-green md:gap-12 columns-1 md:columns-2 h3-break-column"
                              dangerouslySetInnerHTML={{ __html: tabContent.content }}
                            />
                          )}
                        </div>
                      ) : tab.style === 'steps' ? (
                        <div key={`tab-content-${i}`} className="text-center w-full">
                          {tabContent.title && (
                            <h3 className="text-2xl mb-4">{tabContent.title}</h3>
                          )}

                          {tabContent.textBlockNode && (
                            <div
                              className="text-base mb-10"
                              dangerouslySetInnerHTML={{
                                __html: tabContent.textBlockNode?.childMarkdownRemark?.html
                              }}
                            />
                          )}
                          <div className="overflow-x-auto styled-scroll">
                            <ul
                              className="reveal-icons__container relative flex justify-between overflow-hidden mx-auto py-14 px-5"
                              style={{ width: '1208px' }}
                            >
                              {tabContent.steps &&
                                tabContent.steps.map((step, i) => {
                                  return (
                                    <li
                                      key={`step-${i}`}
                                      className="mx-5 cursor-pointer relative shrink-0"
                                      onClick={() => {
                                        setPreviousStep(null)
                                        setActiveStep(i)
                                        setTimeout(() => {
                                          setPreviousStep(i + 1)
                                        }, 400)
                                      }}
                                      onMouseEnter={() => setActiveHover(i)}
                                      onMouseLeave={() => setActiveHover(null)}
                                      style={{ width: 122 }}
                                    >
                                      <ImageWrap
                                        className={`absolute max-w-none transition-all ${
                                          activeStep === i || activeHover === i
                                            ? 'scale-100 z-1'
                                            : 'scale-0'
                                        }`}
                                        {...step.imageHover}
                                        style={{ left: '-33%', top: '-28%' }}
                                      />
                                      <div className="relative z-1">
                                        <ImageWrap
                                          className="relative z-1"
                                          {...step.image}
                                          style={{
                                            width: 122,
                                            height: 139
                                          }}
                                        />
                                        <h4
                                          className={`z-2 leading-tight w-full absolute left-0 top-1/2 -translate-y-1/2 uppercase text-white ${
                                            activeStep === i ? 'text-base' : 'text-xs p-4 '
                                          } font-semibold`}
                                        >
                                          {step.title}
                                        </h4>
                                      </div>
                                    </li>
                                  )
                                })}
                            </ul>
                          </div>
                          <div className={`my-10 ${!previousStep ? 'fade-in' : ''}`}>
                            <h4 className="text-base uppercase font-semibold mb-4">
                              {tabContent.steps[activeStep].title}
                            </h4>
                            <div className="text-base">{tabContent.steps[activeStep].text}</div>
                          </div>
                        </div>
                      ) : homepage ? (
                        <div key={`tab-content-${i}`} className="w-full sm:w-1/3 text-center">
                          <div className="flex flex-col h-full p-3 sm:p-5">
                            {tabContent.image && (
                              <ImageWrap className="mx-auto" {...tabContent.image} />
                            )}

                            <div className="mt-4">
                              <h3 className="font-light text-xl lg:text-3xl">{tabContent.title}</h3>
                              <p className="font-light lg:mt-1 text-xl lg:text-3xl">
                                {tabContent.subtitle}
                              </p>
                            </div>
                            <div
                              className="mt-5 mb-2 text-sm lg:text-base"
                              dangerouslySetInnerHTML={{ __html: tabContent.content }}
                            />

                            {tabContent.ctaLink && (
                              <ArrowButton
                                className="text-sm lg:text-lg font-semibold inline-flex mx-auto mt-auto p-3"
                                href={tabContent.ctaLink}
                                target={tabContent.ctaOpenTab ? '_blank' : null}
                              >
                                {tabContent.ctaText}
                              </ArrowButton>
                            )}
                          </div>
                        </div>
                      ) : (
                        tabContent.image && (
                          <RevealImage
                            tickList
                            key={`reveal-image-${i}`}
                            {...tabContent}
                            icons={icons}
                          />
                        )
                      )
                    })}
                </div>
              )
            })}
          </div>
        )}

        {cta && (
          <div className={`${icons ? 'py-4' : 'py-10'} mx-auto flex justify-center`}>
            <SecondaryButton href={cta.href} {...cta}>
              {cta.text}
            </SecondaryButton>
          </div>
        )}
      </div>
    </div>
  )
}
