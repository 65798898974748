import React, { useState, useEffect, useContext } from 'react'
import NewsSectionTitle from './NewsSectionTitle'
import { CarouselProvider, Slider, Slide, CarouselContext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import Navigation from './Navigation'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { VideoModal } from '@truphone/modals'
import { PrimaryButton } from '@truphone/buttons'

const CurrentItem = (props) => {
  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide)
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])
  const current =
    currentSlide < carouselContext.state.totalSlides
      ? props.slideList[currentSlide]
      : props.slideList[carouselContext.state.totalSlides - 1]

  return current ? (
    <>
      <div className={props.fade ? 'fade-slide' : ''} onAnimationEnd={() => props.removeFade()}>
        {current.cta && (
          <a
            href={current.cta.href}
            className={`hover-blue-line pt-2 block italic font-light ${
              props.next ? 'text-2xl' : 'text-base lg:text-2xl'
            }`}
          >
            {current.quote ? current.quote : current.title}
          </a>
        )}
        {current.subtitle && <p className="text-base mt-4 mb-2">{current.subtitle}</p>}
      </div>

      <div className="flex mt-10">
        {current.companyLogo && current.companyLogo.url && (
          <a href={current.cta.href ? current.cta.href : '#'} className="mr-4 block w-20 shrink-0">
            <ImageWrap
              className={`w-full h-full object-contain`}
              src={current.companyLogo.url}
              alt={current.quoteTitle}
            />
          </a>
        )}
        <div>
          <div className=" text-base mb-1">{current.quoteTitle}</div>
          <p className={`mt-2 text-xs`}>
            <b>{current.quoteAuthor}</b>, {current.quoteAuthorJobTitle}
          </p>
        </div>
      </div>
      {current.cta?.href && (
        <PrimaryButton className={`mt-8`} href={current.cta.href}>
          {current.cta.text}{' '}
        </PrimaryButton>
      )}
    </>
  ) : (
    <></>
  )
}
export default function TechnologyCaseStudiesCarousel(props) {
  const [fade, setFade] = useState(true)

  const handleFade = () => {
    setFade(true)
  }
  const removeFade = () => {
    setFade(false)
  }

  return (
    <div
      className={`py-10`}
      style={{
        background: props.backgroundColor ? props.backgroundColor : 'none',
        color: props.color ? props.color : 'text-black'
      }}
    >
      <div className="container mx-auto px-6">
        {props.slideList && props.slideList.length > 0 && (
          <CarouselProvider
            infinite={true}
            naturalSlideWidth={512}
            naturalSlideHeight={340}
            totalSlides={props.slideList.length}
          >
            <div className={`flex flex-col-reverse lg:flex-row overflow-hidden`}>
              <div className={`w-full lg:w-1/2 lg:pr-10`}>
                {props.title && (
                  <NewsSectionTitle
                    topTitle={props.title}
                    seeAllText={props.seeAllText}
                    onSeeAllClick={props.onSeeAllClick}
                    color={props.color}
                  />
                )}
                <CurrentItem
                  totalSlides={props.slideList.length}
                  slideList={props.slideList}
                  fade={fade}
                  removeFade={removeFade}
                  download={props.download}
                  textNext={props.textNext}
                  handleFade={() => handleFade()}
                />
                {props.slideList.length > 1 && (
                  <div className="mt-8 text-sm text-grey-700">
                    <Navigation handleFade={() => handleFade()} />
                  </div>
                )}
              </div>
              <div className={`w-full lg:w-1/2 mb-6 lg:mb-0`}>
                <Slider style={{ position: 'relative' }}>
                  {props.slideList.map((slide, i) => {
                    return (
                      <Slide index={i} key={`customer-slide-${i}`} onTouchEnd={() => handleFade()}>
                        <div className="relative h-full" style={{ width: '96%' }}>
                          {slide.imageBackgroundColor && (
                            <div
                              className={`${
                                !fade ? 'translate-y-10 translate-x-6' : ''
                              } transition-transform duration-700 absolute top-0 left-0 w-full h-full rounded`}
                              style={{
                                backgroundColor: slide.imageBackgroundColor.hex,
                                color: slide.imageTextColor?.hex,
                                width: 'calc(100% - 2rem)',
                                height: 'calc(100% - 2.5rem)'
                              }}
                            ></div>
                          )}
                          {slide.videoUrl ? (
                            <div
                              className="relative rounded-lg overflow-hidden"
                              style={{ width: 'calc(100% - 2rem)', height: 'calc(100% - 2.5rem)' }}
                            >
                              <ImageWrap
                                className="w-full h-full object-cover block"
                                src={slide.image.url}
                                alt={slide.alt}
                              />
                              <VideoModal isVideo={true} href={slide.videoUrl}>
                                <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                                  <div className="norder-white pulse absolute w-full h-full left-0 top-0 rounded-full border-4"></div>
                                  <img
                                    className="w-20 h-20"
                                    src="/images/icons/icon-play-white.svg"
                                    alt="play"
                                  />
                                </div>
                              </VideoModal>
                            </div>
                          ) : (
                            <a
                              href={slide.href}
                              className={`relative block hover-effect rounded overflow-hidden`}
                              style={{ width: 'calc(100% - 2rem)', height: 'calc(100% - 2.5rem)' }}
                            >
                              <ImageWrap
                                className="w-full h-full object-cover block image-hover-effect transition-all"
                                src={slide.image.url}
                                alt={slide.alt}
                              />
                            </a>
                          )}
                        </div>
                      </Slide>
                    )
                  })}
                </Slider>
              </div>
            </div>
          </CarouselProvider>
        )}
      </div>
    </div>
  )
}
