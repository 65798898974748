import React from 'react'
export default function TechnologyIconsList(props) {
  const centered = props.style === 'centered'
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor?.hex,
        color: props.textColor?.hex
      }}
    >
      <div
        className={`${
          centered ? 'text-center' : ''
        } flex flex-wrap container mx-auto px-4 lg:px-6 py-10 `}
      >
        {props.iconList.map((icon, i) => {
          return (
            <div key={`icon-list-${i}`} className={`w-1/2 lg:w-1/4 px-2 py-4 lg:p-6`}>
              <div
                className={`${
                  centered ? '' : 'flex flex-col sm:flex-row items-start sm:items-center mb-3'
                }`}
              >
                {icon.icon && (
                  <img
                    className={`${centered ? 'mx-auto mb-4' : 'mb-2 sm:mb-0 sm:mr-3'} `}
                    src={icon.icon.url}
                    alt="icon"
                  />
                )}
                {icon.title && (
                  <h4 className={`font-semibold ${centered ? 'mb-4' : ''} leading-tight`}>
                    {icon.title}
                  </h4>
                )}
              </div>
              {icon.text && <div className="text-[13px]">{icon.text} </div>}
            </div>
          )
        })}
      </div>
    </div>
  )
}
