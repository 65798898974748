import React from 'react'
export default function TechnologyList(props) {
  return (
    <div className={`container mx-auto px-4 ${props.title ? 'py-10 lg:py-20' : 'py-10'}`}>
      <h3 className="text-2xl lg:text-4xl text-center mb-8">{props.title}</h3>
      {props.list && (
        <ul className="columns-1 md:columns-3 list-tick">
          {props.list.map((item, i) => {
            return (
              <li
                key={`list-item-${i}`}
                className="mb-3 mx-3 text-sm list-no-style"
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            )
          })}
        </ul>
      )}
    </div>
  )
}
