import { ImageWrap } from '@truphone/gatsby-wrappers'
import React, { useEffect, useState, useRef, useCallback } from 'react'

export default function RevealImage(props) {
  const handleRef = useRef()
  const imageRef = useRef(null)
  const [isResizing, setIsResizing] = useState(false)

  function throttle(fn, wait) {
    var time = Date.now()
    return function () {
      if (time + wait - Date.now() < 0) {
        fn()
        time = Date.now()
      }
    }
  }
  const [isVisible, setIsVisible] = useState(false)

  const callbackFunction = (entries) => {
    const [entry] = entries

    if (entry.isIntersecting) {
      handleRef.current.style.left = `50%`
      imageRef.current.style.clipPath = `inset(0 50% 0 0)`
    }
    setIsVisible(entry.isIntersecting)
  }
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    if (imageRef.current) observer.observe(imageRef.current)

    return () => {
      if (imageRef.current) observer.unobserve(imageRef.current)
    }
  }, [imageRef, options])

  useEffect(() => {
    function onWindowScroll() {
      if (imageRef && imageRef.current && isVisible) {
        const { height, top } = imageRef.current.getBoundingClientRect()
        const percent = ((window.innerHeight - top - height * 0.75) / height) * 100
        const percentRevealed = percent > 100 ? 100 : percent < 1 ? 0 : percent
        handleRef.current.style.left = `${percentRevealed}%`
        imageRef.current.style.clipPath = `inset(0 ${100 - percentRevealed}% 0 0)`
      }
    }
    window.addEventListener('scroll', throttle(onWindowScroll, 20))

    return window.removeEventListener('scroll', onWindowScroll)
  }, [isVisible])

  const setPositioning = useCallback((x) => {
    if (imageRef && imageRef.current) {
      const { left, width } = imageRef.current.getBoundingClientRect()
      const handleWidth = handleRef.current.offsetWidth

      if (x >= left && x <= width + left - handleWidth) {
        handleRef.current.style.left = `${((x - left) / width) * 100}%`
        imageRef.current.style.clipPath = `inset(0 ${100 - ((x - left) / width) * 100}% 0 0)`
      }
    }
  }, [])

  const handleResize = useCallback(
    (e) => {
      if (e.clientX) {
        setPositioning(e.clientX)
      } else if (e.touches[0] && e.touches[0].clientX) {
        setPositioning(e.touches[0].clientX)
      }
    },
    [setPositioning]
  )

  useEffect(() => {
    if (imageRef && imageRef.current) {
      const { left, width } = imageRef.current.getBoundingClientRect()
      const handleWidth = handleRef.current.offsetWidth

      setPositioning(width / 2 + left - handleWidth / 2)
    }
  }, [setPositioning])

  const handleResizeEnd = useCallback(() => {
    setIsResizing(false)

    window.removeEventListener('mousemove', handleResize)
    window.removeEventListener('touchmove', handleResize)
    window.removeEventListener('mouseup', handleResizeEnd)
    window.removeEventListener('touchend', handleResizeEnd)
  }, [handleResize])

  const onKeyDown = useCallback(
    (e) => {
      if (handleRef.current) {
        const { offsetLeft, offsetParent } = handleRef.current

        if (e.code === 'ArrowLeft') {
          setPositioning(offsetLeft + offsetParent.offsetLeft - 10)
        }

        if (e.code === 'ArrowRight') {
          setPositioning(offsetLeft + offsetParent.offsetLeft + 10)
        }
      }
    },
    [setPositioning]
  )

  // Add keydown event on mount
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
  }, [onKeyDown])

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleResize)
      window.addEventListener('touchmove', handleResize)
      window.addEventListener('mouseup', handleResizeEnd)
      window.addEventListener('touchend', handleResizeEnd)
    }

    return () => {
      window.removeEventListener('mousemove', handleResize)
      window.addEventListener('touchmove', handleResize)
      window.removeEventListener('mouseup', handleResizeEnd)
      window.removeEventListener('touchend', handleResizeEnd)
      window.removeEventListener('keyup', onKeyDown)
    }
  }, [isResizing, handleResize, handleResizeEnd, onKeyDown])

  return (
    <div
      className={`flex container mx-auto ${props.icons ? '' : 'px-4 py-10'} ${
        props.imageRight ? '' : 'flex-row-reverse items-center'
      } flex-wrap`}
    >
      <div
        className={`w-full lg:w-1/2 ${
          props.icons
            ? 'lg:border-l border-white border-opacity-50 relative list-lines  px-6 py-2'
            : props.imageRight
            ? 'lg:pr-10'
            : 'pt-10 lg:pt-0 lg:pl-10'
        }`}
      >
        {props.title && <h3 className="text-2xl mb-4">{props.title}</h3>}
        {props.subtitle && (
          <p className="-mt-3 pb-1 text-[13px] text-grey-700 border-b border-grey-300 mb-6">
            {props.subtitle}
          </p>
        )}

        {props.text && (
          <div
            className={`text-base ${props.icons ? 'my-4' : 'mb-4'} ${
              props.tickList ? 'list-tick' : 'list-disc'
            }`}
            dangerouslySetInnerHTML={{ __html: props.text }}
          />
        )}

        {props.footer && (
          <div className="text-sm list-disc" dangerouslySetInnerHTML={{ __html: props.footer }} />
        )}
      </div>
      <div
        className={`w-full lg:w-1/2 ${
          props.icons ? '' : props.imageRight ? 'pt-10 lg:pt-0 lg:pl-10' : 'lg:pr-10'
        }`}
      >
        <div
          className={`relative  ${
            props.icons
              ? 'hidden lg:block line-before'
              : 'flex justify-center w-[280px] md:w-[400px] xl:w-[600px] mx-auto max-w-none'
          }`}
        >
          {props.image && (
            <ImageWrap
              draggable="false"
              className="relative pointer-events-none select-none "
              {...props.image}
            />
          )}
          {props.revealImage && (
            <>
              <div
                ref={imageRef}
                draggable="false"
                className="absolute top-0 left-0 h-full overflow-hidden"
                //style={{ width: percent + '%' }}
              >
                <ImageWrap
                  draggable="false"
                  className="block w-[280px] md:w-[400px]  xl:w-[600px] max-w-none h-full select-none pointer-events-none"
                  // style={{ width: imageWidth }}
                  {...props.revealImage}
                />
              </div>
            </>
          )}
          {props.revealImage && (
            <div
              ref={handleRef}
              onMouseDown={() => setIsResizing(true)}
              onTouchStart={() => setIsResizing(true)}
              className={`${
                isResizing ? 'cursor-ew-resize' : 'cursor-pointer'
              } dragger absolute top-0 left-0 h-full w-1 bg-white shadow`}
              //style={{ left: percent + '%' }}
            ></div>
          )}
        </div>
      </div>
    </div>
  )
}
