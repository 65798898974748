import { ArrowButton } from '@truphone/buttons'
import React from 'react'
export default function NewsSectionTitle(props) {
  return (
    <div
      className={`text-base flex items-center justify-between py-2 mb-2 border-b border-grey-200`}
    >
      <p
        className="overline-text opacity-70 uppercase tracking-xwidest"
        style={{ color: props.color ? props.color : 'currentcolor' }}
      >
        {props.topTitle}
      </p>
      {props.seeAllText && (
        <ArrowButton
          className={`${
            props.color === '#fff' ? 'text-white' : 'text-blue-100'
          } text-xs md:text-base pr-4 cursor-pointer`}
          onClick={props.onSeeAllClick}
        >
          {props.seeAllText}
        </ArrowButton>
      )}
    </div>
  )
}
